@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --res-pixel: (0.8px + 0.05335vmin);
}

body {

    background-color: #f6f4ef;
    /* transition: height 999999s; */
    /* background-color: #efebe1; */
    font-family: 'Nunito Sans', sans-serif;
}

@keyframes soft-fade-in-anim {
    0% {
        opacity: 0%;
        /* transform: scale(102.5%); */
    }
    100% {
        opacity: 100%;
        /* transform: scale(100%); */
    }
}
  
.soft-fade-in {
    animation: soft-fade-in-anim 0.75s forwards;
}

/* ===== Scrollbar CSS Start ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #7d7d7d #c0c0c0;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #c0c0c0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #7d7d7d;
    border-radius: 10px;
    /* border: 0px solid #000000; */
}
/* ===== Scrollbar CSS End ===== */

.bullet-points:before { 
    content: '\b7\a0'; /* \b7 is a middot, \a0 is a space */
    font-size: 2rem;
}
.bullet-points {
    list-style: none;
    text-indent: -1em; /* helps make it look more like it's a bullet. */
}

.bg-image {
    /* background-image: url();
    background-position: center;
    /* height: calc(var(--res-pixel) * 700); */
    /* height: 100vh;
    background-attachment: fixed; */

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    transform: scale(1.0); 
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(./content/main-bg.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

h1, h2 {
    font-family: 'Caveat', cursive;
}

.signature-font {
    font-family: 'Satisfy', cursive;
}

.cream-bg {
    background-color: #f6f4ef;
    /* background-color: #FAF9F6; */
}

.skill-bg-gradient {
    background: rgb(246,244,239);
    background: linear-gradient(169deg, rgba(246,244,239,1) 65%, rgba(236,236,236,1) 100%);
}

.modalStyle .ant-modal-content{
    background-color: #f6f4ef;
 }

.vertical-shadow {

    -webkit-box-shadow: 0px 0px 55px 50px rgba(246, 244, 239, 1);
    -moz-box-shadow: 0px 0px 55px 50px rgba(246, 244, 239, 1);
    box-shadow: 0px 0px 55px 50px rgba(246, 244, 239, 1);
}

.header-bg {
    background-color: url(./content/main-bg.jpg);
    background-position: center;
    /* height: calc(var(--res-pixel) * 700); */
    height: 100%;
    background-attachment: fixed;
}

#inner-work-parent .hidden-work-child {
    visibility: hidden;
}

#inner-work-parent:hover .hidden-work-child {
    visibility: visible;
}

.grow-work { 
    transition: all .2s ease-in-out; 
}

.grow-work:hover, .grow-work:active {
    transform: scale(1.015); 
}

.grow-icon { 
    transition: all .2s ease-in-out; 
}

.grow-icon:hover, .grow-icon:active { 
    transform: scale(1.15);
}

.grow-skill { 
    transition: all .2s ease-in-out; 
}

.grow-skill:hover, .grow-skill:active {
    transform: scale(1.025);
}

@keyframes squeezeIn {
    0% {
        transform: scale(0) translate(-50%, -50%);
    }
    100% {
        transform: scale(1.0) translate(-50%, -50%);
        /* -webkit-transform: scale(1.0); */
    }
}

@keyframes fade-in {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes move-from-top {
    0% {
        transform:translateY(-7.5%);
    }
    100% {
        transform:translateY(0%);
    }
}

.squeezeInAnimation {
    animation: squeezeIn 0.2s normal
}

.fade-in {
    animation: fade-in 2.5s normal, move-from-top 1.5s normal;
}

.fade-in-skillcard {
    animation: fade-in 1.5s normal, move-from-top 1.5s normal;
}

.text-highlight {
    @apply bg-[#FFE800]/95 text-black;
}

.main-padding {
    @apply px-[10%] sm:px-[15%] lg:px-[20%] xl:px-[30%];
}

.absolute-center {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.portfolio-pic {
    background: url(./content/portfolio-picture.jpg);
    background-size: cover;
    background-position: center 15%;
    width: 144px;
    height: 144px;
}

.svg-grey-filter {
    filter: invert(45%) sepia(9%) saturate(592%) hue-rotate(182deg) brightness(96%) contrast(88%);
}

.svg-white-filter {
    filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(30deg) brightness(108%) contrast(101%);
}

.box-fade-inset {

    -webkit-box-shadow: inset 0px -110px 108px 3px rgba(250,249,246,1);
    -moz-box-shadow: inset 0px -110px 108px 3px rgba(250,249,246,1);
    box-shadow: inset 0px -110px 108px 3px rgba(250,249,246,1);
}

.text-xs-res {
    font-size: calc(var(--res-pixel) * 12);
    line-height: calc(var(--res-pixel) * 16);
}

.text-sm-res {
    font-size: calc(var(--res-pixel) * 14);
    line-height: calc(var(--res-pixel) * 20);
}

.text-base-res {
    font-size: calc(var(--res-pixel) * 16);
    line-height: calc(var(--res-pixel) * 24);
}

.text-lg-res {
    font-size: calc(var(--res-pixel) * 18);
    line-height: calc(var(--res-pixel) * 28);
}

.text-xl-res {
    font-size: calc(var(--res-pixel) * 20);
    line-height: calc(var(--res-pixel) * 28);
}

.text-2xl-res {
    font-size: calc(var(--res-pixel) * 24);
    line-height: calc(var(--res-pixel) * 32);
}

.text-3xl-res {
    font-size: calc(var(--res-pixel) * 30);
    line-height: calc(var(--res-pixel) * 36);
}

.text-4xl-res {
    font-size: calc(var(--res-pixel) * 36);
    line-height: calc(var(--res-pixel) * 40);
}

.text-5xl-res {
    font-size: calc(var(--res-pixel) * 48);
    line-height: 1;
}

.text-6xl-res {
    font-size: calc(var(--res-pixel) * 60);
    line-height: 1;
}

.text-7xl-res {
    font-size: calc(var(--res-pixel) * 72);
    line-height: 1;
}

.text-8xl-res {
    font-size: calc(var(--res-pixel) * 96);
    line-height: 1;
}

.text-9xl-res {
    font-size: calc(var(--res-pixel) * 128);
    line-height: 1;
}